<info-join-deals></info-join-deals>

<!--<mat-spinner *ngIf="waitingCheckCustomTier" class="__loading" diameter="40"></mat-spinner>-->

<ng-container>
<section class="section-alert">
  <div class="alert alert-warning" role="alert" *ngIf="needToWaitTime">
    <div class="alert-icon icomoon-lock"></div>
    <div class="alert-heading">
      <p>{{'youHaveLockupPeriod' | translate}} {{getTranslatedShowPeriod(needToWaitTime)}}.</p>
      <p>{{'ifWantShortenTimeLockPeriod' | translate}}</p>
    </div>
    <div class="alert-button">
      <a href="https://help.bullperks.com/allocation-per-tier/?highlight=Lockers" target="_blank" mat-button
        color="primary">
        {{'whatDoesItMean' | translate}}
      </a>
    </div>
  </div>
</section>

<section class="dashboard-section __deal-main-info pt-40">
  <div class="card">
    <div class="card-header d-flex flex-wrap align-items-center justify-content-between">
      <div class="token-name token-name-inline">
        <div class="token-name__img">
          <img [src]="deal?.logoURL?deal?.logoURL:'../../assets/images/no-image.svg'" />
        </div>
        <div class="token-name__info">
          <div class="token-title">{{deal?.name}}</div>
          <div class="token-symbol">{{deal?.rewardTokenSymbol}}</div>
        </div>
      </div>

      <div class="social-list m-auto" *ngIf="hasSocialMedia()">
        <a *ngIf="deal?.twitterURL" [href]="deal?.twitterURL" target="_blank">
          <img src="../../assets/images/social/twitter.svg" alt="">
        </a>
        <a *ngIf="deal?.mediumURL" [href]="deal?.mediumURL" target="_blank">
          <img src="../../assets/images/social/medium.svg" alt="">
        </a>
        <a *ngIf="deal?.youTubeURL" [href]="deal?.youTubeURL" target="_blank">
          <img src="../../assets/images/social/youtube.svg" alt="">
        </a>
        <a *ngIf="deal?.facebookURL" [href]="deal?.facebookURL" target="_blank">
          <img src="../../assets/images/social/facebook.svg" alt="">
        </a>
        <a *ngIf="deal?.telegramURL" [href]="deal?.telegramURL" target="_blank">
          <img src="../../assets/images/social/telegram.svg" alt="">
        </a>
        <a *ngIf="deal?.linkedInURL" [href]="deal?.linkedInURL" target="_blank">
          <img src="../../assets/images/social/linkedin.svg" alt="">
        </a>
        <a *ngIf="deal?.instagramURL" [href]="deal?.instagramURL" target="_blank">
          <img src="../../assets/images/social/instagram.svg" alt="">
        </a>
        <a *ngIf="deal?.telegramChannelURL" [href]="deal?.telegramChannelURL" target="_blank">
          <img src="../../assets/images/social/telegram.svg" alt="">
        </a>
      </div>

      <button *ngIf="deal.rewardToken && !hasSocialMedia()" class="address-btn --copy m-auto"><span
          [cdkCopyToClipboard]="deal.rewardToken">{{deal.rewardToken}}</span></button>

      <div class="d-lg-flex align-items-center __deal-header-link">
        <a [href]="rewardToken | blockExplorerAddressPipe: deal?.chainId" target="_blank" mat-stroked-button
          class="__bscscan-link" *ngIf="rewardToken">
          {{'view' | translate}} BSC Scan
        </a>

        <button mat-stroked-button class="__about-link ms-lg-2 btn-icon btn-icon-left" (click)="scrollToDescription()">
          <i class="icon icomoon-arrow-down"></i>
          {{'aboutProject' | translate}}
        </button>

        <button mat-raised-button color="primary" class="__join-link ms-lg-2 btn-icon btn-icon-left"
          (click)="joinDealClick()" [disabled]="!userTier"
          *ngIf="kycConfirmed && !(dealRegistration?.signature)">
          <i class="icon icomoon-checked"></i>
          {{'joinDeal' | translate}}
        </button>

        <a [href]="claimURL || defaultClaimingUrl" target="_blank" mat-raised-button color="primary" class="__join-link ms-lg-2 btn-icon btn-icon-left"
          [disabled]="!userTier"
          *ngIf="kycConfirmed && dealRegistration?.signature && isFinished && !hideClaimButton">
          <i class="icon icomoon-checked"></i>
          {{'claim' | translate}}
        </a>

        <a href="https://nft.planetmojo.io/" target="_blank" mat-raised-button color="primary" class="__join-link ms-lg-2 btn-icon btn-icon-left"
           *ngIf="kycConfirmed && dealRegistration?.signature && isFinished && dealAddress === '0x8290da01a2527a0695fac43fd610b587594b3de5'">
          <i class="icon icomoon-checked"></i>
          {{'mint' | translate}}
        </a>

        <!-- <button mat-raised-button color="primary" class="__join-link ms-lg-2 btn-icon btn-icon-left"
          *ngIf="kycConfirmed && dealRegistration?.signature" disabled>
          <i class="icon icomoon-checked"></i>
          You are joined
        </button> -->

      </div>

      <div class="social-list-wrapper" *ngIf="hasSocialMedia()">
        <div class="social-list">
          <a *ngIf="deal?.twitterURL" [href]="deal?.twitterURL" target="_blank">
            <img src="../../assets/images/social/twitter.svg" alt="">
          </a>
          <a *ngIf="deal?.mediumURL" [href]="deal?.mediumURL" target="_blank">
            <img src="../../assets/images/social/medium.svg" alt="">
          </a>
          <a *ngIf="deal?.youTubeURL" [href]="deal?.youTubeURL" target="_blank">
            <img src="../../assets/images/social/youtube.svg" alt="">
          </a>
          <a *ngIf="deal?.facebookURL" [href]="deal?.facebookURL" target="_blank">
            <img src="../../assets/images/social/facebook.svg" alt="">
          </a>
          <a *ngIf="deal?.telegramURL" [href]="deal?.telegramURL" target="_blank">
            <img src="../../assets/images/social/telegram.svg" alt="">
          </a>
          <a *ngIf="deal?.linkedInURL" [href]="deal?.linkedInURL" target="_blank">
            <img src="../../assets/images/social/linkedin.svg" alt="">
          </a>
          <a *ngIf="deal?.instagramURL" [href]="deal?.instagramURL" target="_blank">
            <img src="../../assets/images/social/instagram.svg" alt="">
          </a>
          <a *ngIf="deal?.telegramChannelURL" [href]="deal?.telegramChannelURL" target="_blank">
            <img src="../../assets/images/social/telegram.svg" alt="">
          </a>
        </div>
        <button *ngIf="deal.rewardToken" class="address-btn --copy"><span
          [cdkCopyToClipboard]="deal.rewardToken">{{deal.rewardToken}}</span></button>
      </div>

    </div>

    <div class="card-content" *ngIf="!deal.preview">
      <div class="row align-items-center">
        <div class="col-md-6 col-lg-3 mb-4 mb-lg-0 order-1">
          <div class="d-flex align-items-center">
            <button *ngIf="now<finishTimestamp" (click)="addToGoogleCalendar()" mat-icon-button
              class="__add-to-google me-3" matTooltip="Add to Google Calendar" matTooltipPosition="above">
              <img src="../../assets/images/google-calendar.svg" alt="">
            </button>
            <div>
              <!-- Upcoming & Progress -->
              <div *ngIf="isUpcoming || isStarted">
                <div class="subtitle-1 mb-1" *ngIf="isUpcoming">{{'startsIn' | translate}}</div>
                <span class="subtitle-1 mb-1" *ngIf="now > startTimestamp">{{'closesIn' | translate}}</span>

                <div class="countdown h6 mb-1" >
                  <span *ngIf="timerViewDays">{{timerViewDays}}{{'time.day' | translate}}</span>
                  <span *ngIf="timerViewHours"> {{timerViewHours}}{{'time.hour' | translate}}</span>
                  <span *ngIf="timerViewMin && !timerViewDays"> {{timerViewMin}}{{'time.minute' | translate}}</span>
                  <span *ngIf="timerViewSec && !timerViewDays"> {{timerViewSec}}{{'time.second' | translate}}</span>
                </div>
              </div>

              <!-- Finished -->
              <div class="subtitle-1 mb-1" *ngIf="isFinished">{{'dealStatus' | translate}}</div>
              <div class="h6 mb-1" *ngIf="isFinished">{{'finished' | translate}}</div>

              <div *ngIf="isUpcoming" class="small-text text-muted">{{startTimestamp* 1000 | date:'dd MMM, yyyy H:mm a' : undefined :
                (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
                <div *ngIf="!isUpcoming" class="small-text text-muted">{{finishTimestamp* 1000 | date:'dd MMM, yyyy H:mm a' : undefined :
                (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 mb-4 mb-md-0 order-2 order-md-3 order-lg-2">
          <div class="subtitle-1 mb-1">{{deal.dealType == 3 ? ('totalPurchased' | translate) : ('tokensPurchased' | translate)}}</div>
          <div class="token-name token-name-sm mb-1" *ngIf="deal.dealType != 3">
            <div class="token-name__img">
              <img [src]="firstTokenIconUrl" alt="" />
            </div>
            <div class="token-name__info">
              <div class="token-title">
                {{firstTokenDistributionQuantity| number: '1.0-2'}} {{deal?.customTokenName?? deal?.rewardTokenSymbol}}
              </div>
            </div>
          </div>
          <div class="token-name token-name-sm mb-1" *ngIf="deal.dealType != 3 && deal.secondTokenCustomPrice">
            <div class="token-name__img">
              <img [src]="secondTokenIconUrl" alt="" />
            </div>
            <div class="token-name__info">
              <div class="token-title">
                {{secondTokenDistributionQuantity | number: '1.0-2'}} {{deal?.secondTokenCustomName?? deal?.rewardTokenSymbol}}
              </div>
            </div>
          </div>
          <div class="small-text text-muted">
            <span matTooltip="1 {{paymentTokenSymbol}} = {{tokensPerETH| number: '1.0-2'}} {{deal?.rewardTokenSymbol}}"
              matTooltipPosition="above">
              {{totalRaise| shiftDecimals:-paymentDecimal| number: '1.0-2'}} {{deal?.paymentTokenSymbol}}
            </span>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 mb-4 mb-md-0 order-3 order-md-4 order-lg-3">
          <div class="subtitle-1 mb-1">{{'totalRaise' | translate}}</div>
          <div class="token-name token-name-sm mb-1" *ngIf="deal.dealType != 3">
            <div class="token-name__img">
              <img [src]="firstTokenIconUrl" />
            </div>
            <div class="token-name__info">
              <div class="token-title">
                {{firstTokenMaxDistributedTokenAmount| shiftDecimals:-rewardDecimals| number: '1.0-2'}}
                {{deal?.customTokenName?? deal?.rewardTokenSymbol}}
              </div>
            </div>
          </div>
          <div class="token-name token-name-sm mb-1" *ngIf="deal.dealType != 3 && deal.secondTokenCustomPrice">
            <div class="token-name__img">
              <img [src]="secondTokenIconUrl" alt="" />
            </div>
            <div class="token-name__info">
              <div class="token-title">
                {{secondTokenMaxDistributedTokenAmount| shiftDecimals:-rewardDecimals| number: '1.0-2'}}
                {{deal?.secondTokenCustomName}}
              </div>
            </div>
          </div>
          <span class="small-text text-muted">
            {{getTotalRaiseETH(deal)| number: '1.0-2'}} {{paymentTokenSymbol}}
          </span>
        </div>
        <div class="col-md-6 col-lg-3 mb-md-4 mb-lg-0 order-4 order-md-2 order-lg-4">
          <div class="subtitle-1 mb-1">{{'dealProgress' | translate}}</div>

          <div class="__deal-progress">
            <div *ngIf="deal.dealType != 3 && !deal?.secondTokenCustomPrice" class="caption text-right text-muted mb-1">{{getDistributedLeft | shiftDecimals:-rewardDecimals|
              number: '1.0-2' }} {{'left' | translate}}</div>
            <div class="progress progress-xs">
              <div class="progress-bar" role="progressbar" [style.width.%]="getDistributedPercent"></div>
            </div>
            <div class="progress-legend d-flex justify-content-between">
              <div class="progress-legend__percent">{{getDistributedPercent| number: '1.0-2'}}%</div>
              <div>
                <!-- <div *ngIf="deal.dealType != 3" class="progress-legend__value">{{firstTokenDistributionQuantity  | shiftDecimals:-rewardDecimals| number:
                  '1.0-2'}}/{{firstTokenMaxDistributedTokenAmount | shiftDecimals:-rewardDecimals| number: '1.0-2'}}</div>
                <div *ngIf="deal.dealType != 3 && deal?.secondTokenCustomPrice" class="progress-legend__value mt-2">{{secondTokenDistributionQuantity | shiftDecimals:-rewardDecimals| number:
                  '1.0-2'}}/{{secondTokenMaxDistributedTokenAmount | shiftDecimals:-rewardDecimals| number: '1.0-2'}}</div> -->

                  <div *ngIf="deal.dealType != 3" class="progress-legend__value d-flex align-items-center">
                    <span>{{firstTokenDistributionQuantity | number: '1.0-2'}}/{{firstTokenMaxDistributedTokenAmount | shiftDecimals:-rewardDecimals| number: '1.0-2'}}</span>
                    <img [src]="firstTokenIconUrl" />
                  </div>
                  <div *ngIf="deal.dealType != 3 && deal?.secondTokenCustomPrice" class="progress-legend__value d-flex align-items-center mt-2">
                    <span>{{secondTokenDistributionQuantity | number: '1.0-2'}}/{{secondTokenMaxDistributedTokenAmount | shiftDecimals:-rewardDecimals| number: '1.0-2'}}</span>
                    <img [src]="secondTokenIconUrl" />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="dashboard-section __deal-general-info pt-40" *ngIf="usersDebtAmount || usersTotalPaymentAmount">
  <div class="row">
    <div class="col-lg-4 mb-3 mb-lg-0" *ngIf="usersDebtAmount && deal.dealType != 3">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-1">{{'dealPrice' | translate}}</div>
        <div class="h6">
          1 {{deal.customTokenName?? rewardTokenSymbol}} = {{firstTokenCustomPrice | shiftDecimals:-paymentDecimal| number: '1.0-5'}}
          {{paymentTokenSymbol}}
        </div>
        <div class="h6" *ngIf="deal.secondTokenCustomPrice">
          1 {{deal.secondTokenCustomName?? rewardTokenSymbol}} = {{secondTokenCustomPrice | shiftDecimals:-paymentDecimal| number: '1.0-5'}}
          {{paymentTokenSymbol}}
        </div>
      </div>
    </div>
    <div class="col-lg-4 mb-3 mb-lg-0" *ngIf="usersDebtAmount && deal.dealType != 3">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-1">{{'yourAllocation' | translate}}</div>
        <div class="token-name token-name-sm">
          <div class="token-name__img">
            <img [src]="firstTokenIconUrl" width="24" />
          </div>
          <div class="token-name__info">
            <div class="token-title h6">
              {{firstTokenUserTotalDebtAmount| number: '1.0-4'}} {{deal.customTokenName?? rewardTokenSymbol}}
            </div>
          </div>
        </div>
        <div class="token-name token-name-sm" *ngIf="deal.secondTokenCustomPrice">
          <div class="token-name__img">
            <img [src]="secondTokenIconUrl" width="24" />
          </div>
          <div class="token-name__info">
            <div class="token-title h6">
              {{secondTokenUserTotalDebtAmount| number: '1.0-4'}} {{deal.secondTokenCustomName?? rewardTokenSymbol}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4" *ngIf="usersTotalPaymentAmount">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-1">{{'yourDeposit' | translate}}</div>
        <div class="token-name token-name-sm">
          <div class="token-name__img">
            <img [src]="paymentTokenSymbol | imgTokenPipe" width="24" />
          </div>
          <div class="token-name__info">
            <div class="token-title h6">
              {{usersTotalPaymentAmount| number: '1.0-4'}} {{paymentTokenSymbol}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<section class="dashboard-section __deal-general-info pt-40">
  <div class="row">
    <div class="col">
      <div class="card min-h-100">
        <!-- If deal is finished & not invested -->
        <div class="card-header d-block" *ngIf="now>finishTimestamp">
          <div class="row">
            <div class="col-lg mb-3 mb-lg-0" *ngIf="deal?.emissionDescription">
              <div class="caption text-muted mb-1">{{'vesting' | translate}}</div>
              <div class="small-text">{{deal?.emissionDescription}}</div>
            </div>
            <div class="col-lg mb-3 mb-lg-0" *ngIf="deal?.policy">
              <div class="caption text-muted mb-1">{{'policy' | translate}}</div>
              <div class="small-text">{{deal?.policy}}</div>
            </div>
            <div class="col-lg mb-3 mb-lg-0">
              <div class="caption text-muted mb-1">{{'dealPrice' | translate}}</div>
              <div class="small-text">
                1 {{deal.customTokenName?? rewardTokenSymbol}} = {{firstTokenCustomPrice | shiftDecimals:-paymentDecimal| number: '1.0-5'}}
                {{paymentTokenSymbol}}
              </div>
              <div class="small-text" *ngIf="deal.secondTokenCustomPrice">
                1 {{deal.secondTokenCustomName?? rewardTokenSymbol}} = {{secondTokenCustomPrice | shiftDecimals:-paymentDecimal| number: '1.0-5'}}
                {{paymentTokenSymbol}}
              </div>
            </div>
          </div>
        </div>

        <div class="card-header d-block" *ngIf="now<finishTimestamp && isCollectWalletDeal">
          <div class="d-md-flex">
            <div>
              <div class="caption text-muted mb-1">{{deal.dealType == 3 ? ('yourWalletAddress' | translate) : ('emissionAddress' | translate)}}</div>
              <div class="d-flex align-items-center mb-1">
                <span class="icon icon-sm icomoon-info text-error me-2" *ngIf="!emissionAddress"></span>
                <img [src]="deal?.targetNetwork | imgNetworkPipe" class="logo-20 me-2" />
                <div class="__emission-address small-text">{{emissionAddress ? emissionAddress : (deal.dealType == 3 ? ('addWalletAddressClickingAddButton' | translate) : ('addEmissionAddressClickingAddButton' | translate))}}</div>
              </div>
              <div class="caption text-muted">{{deal.dealType == 3 ? ('neededToConfirmYourPayment' | translate) : ('yourWalletAddressWhereProjectTokensSentYou' | translate)}}</div>
            </div>

            <div class="__emission-address__actions mt-3 mt-lg-0 ms-md-auto">
              <button mat-raised-button color="primary" class="__emission-add btn-icon btn-icon-left"
                (click)="openAddAddressDlg()" [disabled]="!account || !isAuthorized" *ngIf="!emissionAddress">
                <i class="icon icomoon-plus"></i>
                {{'add' | translate}}
              </button>
            </div>
          </div>

          <!--<div *ngIf="!kycConfirmed || !isApprovedPaymentToken">
            <mat-form-field appearance="fill" hideRequiredMarker>
              <mat-label>Emission address</mat-label>
              <input matInput
                     type="text"
                     disabled
              >
              <mat-hint>
                The wallet address where you want the project tokens sent to you
              </mat-hint>
            </mat-form-field>
          </div>

          <div *ngIf="kycConfirmed && canPayMore && isApprovedPaymentToken">
            <mat-form-field appearance="fill" hideRequiredMarker>
              <mat-label>Emission address</mat-label>
              <input matInput
                     type="text"
                     name="emissionAddress"
                     [(ngModel)]="emissionAddress"
                     required>
              <mat-hint>
                The wallet address where you want the project tokens sent to you
              </mat-hint>
              <mat-error>Invalid wallet address</mat-error>
            </mat-form-field>
          </div>-->
        </div>

        <div class="card-content pb-0" *ngIf="!deal.preview">
          <div class="row">
            <div class="col-lg-4 mb-4" *ngIf="deal.dealType != 3">
              <div class="caption text-muted mb-1">{{'targetNetwork' | translate}}</div>
              <div class="network-name">
                <img [src]="targetNetwork | imgNetworkPipe" class="network-name__img" alt="" />
                <span class="network-name__title small-text">{{targetNetwork|networkName}}</span>
              </div>
            </div>
            <div [ngClass]="{'col-lg-8': deal.dealType == 3, 'col-lg-4': deal.dealType != 3}" class="mb-4">
              <div class="caption text-muted mb-1">{{'deposit' | translate}}</div>
              <div class="network-name">
                <img [src]="paymentTokenSymbol | imgTokenPipe" class="network-name__img" alt="" />
                <span class="network-name__title small-text">{{paymentTokenSymbol}}</span>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="caption text-muted mb-1">{{'type' | translate}}</div>
              <div class="small-text">
                <div class="subtitle-1">{{getTranslatedDealTypeName(deal?.dealType)}}</div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="caption text-muted mb-1">{{'start' | translate}}</div>
              <div class="small-text">{{startTimestamp* 1000 | date :'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="caption text-muted mb-1">{{'end' | translate}}</div>
              <div class="small-text">{{finishTimestamp* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="caption text-muted mb-1">{{deal.dealType == 3 ? ('ipoDate' | translate) : ('tokenGeneration' | translate)}}</div>
              <div class="small-text" *ngIf="!deal?.hideTgeDate">{{startClaimTimestamp* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
              <div class="small-text" *ngIf="deal?.hideTgeDate">{{'toBeAnnounced' | translate}}</div>
            </div>
            <div class="col-lg-12 mb-4" *ngIf="deal?.fees">
              <div class="caption text-muted mb-1">{{'fees' | translate}}</div>
              <div class="small-text">{{deal?.fees}}</div>
            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="now<finishTimestamp && deal.dealType != 3">
          <div class="row">
            <div class="col-lg-4 mb-3 mb-lg-0" *ngIf="deal?.emissionDescription">
              <div class="caption text-muted mb-1">{{'vesting' | translate}}</div>
              <div class="small-text">{{deal?.emissionDescription}}</div>
            </div>
            <div class="col-lg-4 mb-3 mb-lg-0" *ngIf="deal?.policy && !deal.preview">
              <div class="caption text-muted mb-1">{{'policy' | translate}}</div>
              <div class="network-name">
                <span class="network-name__title small-text">{{ deal?.policy }}</span>
              </div>
            </div>
            <div class="col-lg-4" *ngIf="!deal.preview">
              <div class="caption text-muted mb-1">{{'minRaise' | translate}}</div>
              <div class="network-name">
                <span class="network-name__title small-text">{{minimumRaise| shiftDecimals:-rewardDecimals| number:
                  '1.0-2'}} {{paymentTokenSymbol}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 mt-4 mt-lg-0" *ngIf="!deal.preview && now < finishTimestamp && kycConfirmed">
      <div class="card p-4 min-h-100">
        <section>
          <div class="subtitle-1 mb-1">{{'yourTier' | translate}}</div>
          <div class="__current-tier mb-4 justify-content-between">
            <div>
              <img [src]="userTierIndex | imgTierPipe" alt="" />
              <span class="h6">{{getTranslatedTier(userTierIndex)}}</span>
            </div>
            <div *ngIf="userCustomRaise > 0" class="ticket-increased mb-2" matTooltip="">+{{userCustomRaise}}%</div>
          </div>
          <div class="user-data">
            <span *ngIf="feePercent">
              {{ 'yourFee' | translate }}: {{feePercent}}%
            </span>
            <span class="user-balance">
              {{ 'yourBalance' | translate }}: {{balance| number: '1.0-2'}} {{paymentTokenSymbol}}
            </span>
          </div>
          <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field mb-4">
          <img [src]="paymentTokenSymbol | imgTokenPipe" />
          <mat-label>{{paymentTokenSymbol}}</mat-label>
          <input matInput 
                  type="number" 
                  [(ngModel)]="usersTicketSize" 
                  [style.width]="usersTicketSize.toString().length + 'ch'"
                  [min]="deal.dealType == 3 ? usersDebtAmount >= deal.minRewardTokenAmount ? 1 : deal.minRewardTokenAmount / tokensPerETH : 0" 
                  [max]="ticketSizeToNumber - usersTotalPaymentAmount| number: '1.0-4'" 
                  required
                  [ngClass]="amountInputClass"
                  (change)="recalcTicketSizeChange($event.target)"
                  [disabled]="deal.dealType == 2 || deal.dealType == 3 || !userTier || !account || !kycConfirmed || 0 <needToWaitTime || payInputDisabled">
          <button mat-button color="primary" class="max-btn" (click)="setMaxUsersTicketSize(usersTotalPaymentAmount, usersDebtAmount)">MAX</button>
          <mat-hint>
            <div class="d-flex align-items-center justify-content-between">
              <span *ngIf="deal.dealType != 3">1 {{paymentTokenSymbol}} = {{deal.dealType == 3 ? (tokensPerETH| number: '1.3-3') : (tokensPerETH| number: '1.0-2')}} {{deal?.rewardTokenSymbol}}</span>

              <span *ngIf="userTier && canPayMore && !payInputDisabled">Max: {{ticketSizeToNumber - usersTotalPaymentAmount| number: '1.0-4'}}
                {{paymentTokenSymbol}}</span>
              <span *ngIf="!canPayMore">Max: 0 {{paymentTokenSymbol}}</span>
            </div>
          </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field mb-4">
          <img [src]="deal?.logoURL?deal?.logoURL:'../../assets/images/no-image.svg'" />
          <mat-label>{{deal?.rewardTokenSymbol}}</mat-label>
          <input matInput
                 required
                 type="number"
                 [(ngModel)]="usersTicketSizeInRewardTokens"
                 [style.width]="usersTicketSizeInRewardTokens.toString().length + 'ch'"
                 [min]="deal.dealType == 2 ? 1 : deal.dealType == 3 ? usersDebtAmount >= deal.minRewardTokenAmount ? 1 : deal.minRewardTokenAmount : 0"
                 [max]="userTierMaxTokens - usersDebtAmount | number: '1.0-4'"
                 (change)="handleTicketSizeChange($event.target)"
                 (keypress)="handleKeyDown($event)"
                 [ngClass]="amountInputClass"
                 [disabled]="!userTier || !account || !kycConfirmed || 0 < needToWaitTime">
          <button mat-button color="primary" class="max-btn" (click)="setMaxUsersTicketSize(usersTotalPaymentAmount, usersDebtAmount)">MAX</button>
          <mat-hint>
            <div class="d-flex align-items-center justify-content-between">
              <span>1 {{rewardTokenSymbol}} = {{tokenPrice| shiftDecimals:-paymentDecimal| number: '1.0-5'}}
                {{paymentTokenSymbol}}</span>
              <span *ngIf="userTier && canPayMore">Max: {{userTierMaxTokens - usersDebtAmount| number: '1.0-4'}} {{rewardTokenSymbol}}</span>
              <span *ngIf="!canPayMore">Max: 0 {{rewardTokenSymbol}}</span>
            </div>
          </mat-hint>
        </mat-form-field>

        <!-- If not login & can pay -->
        <div *ngIf="canPayMore && isAuthorized">
          <div class="row gx-2">
            <!-- Approve -->
            <div class="col-lg"
              matTooltip="{{!kycConfirmed ? ('youMustAuthorisedCompleteKycJoiningDeals' | translate) : ''}}"
              matTooltipPosition="above">
              <button mat-raised-button color="primary" class="w-100 btn-icon btn-icon-left"
                (click)="approvePaymentClick()" [ngClass]="{'btn-loading': waiting }"
                [disabled]="waiting || !kycConfirmed || !userTier || isApprovedPaymentToken">
                <i class="icon icomoon-checkmark"></i>
                {{'approve' | translate}}
              </button>
            </div>

            <!-- equity deal pay button -->
            <div class="col-lg pay-wrapper" *ngIf="allowPay && isCollectWalletDeal && 0==needToWaitTime && isAuthorized"
              matTooltip="{{!isApprovedPaymentToken ? ('mustApproveExactAmount' | translate) : !emissionAddress ? (deal.dealType == 3 ? ('addWalletAddressUnderDealCountdown' | translate) : ('addEmissionAddressUnderDealCountdown' | translate)) : ''}}"
              matTooltipPosition="above">
              <button mat-raised-button color="primary" class="w-100" (click)="payClickWithWalletAddress()"
                [ngClass]="{'btn-loading': waiting && isApprovedPaymentToken}"
                [disabled]="waiting || !emissionAddress || !userTier || !account|| !isApprovedPaymentToken">
                {{'pay' | translate}}
              </button>
            </div>

            <!-- default deal pay button -->
            <div class="col-lg pay-wrapper" *ngIf="allowPay && !isCollectWalletDeal && 0==needToWaitTime && isAuthorized"
                 matTooltip="{{!isApprovedPaymentToken ? ('mustApproveExactAmount' | translate) : ''}}"
                 matTooltipPosition="above">
              <button mat-raised-button color="primary" class="w-100" (click)="payClick()"
                [ngClass]="{'btn-loading': waiting}"
                [disabled]="waiting || !userTier || !account || !isApprovedPaymentToken">
                {{'pay' | translate}}
              </button>
            </div>

            <div class="col-lg" *ngIf="!allowPay">
              <button mat-raised-button color="secondary" type="button"
                matTooltip="{{payTooltip}}" matTooltipPosition="above" class="w-100">
                <div class="countdown d-flex align-items-center justify-content-center">
                  <span *ngIf="timerViewDays">{{timerViewDays}}{{'time.day' | translate}} </span>
                  <span *ngIf="timerViewHours"> {{timerViewHours}}{{'time.hour' | translate}} </span>
                  <span *ngIf="timerViewMin && !timerViewDays"> {{timerViewMin}}{{'time.minute' | translate}} </span>
                  <span *ngIf="timerViewSec && !timerViewDays"> {{timerViewSec}}{{'time.second' | translate}}</span>
                </div>
              </button>
            </div>

            <div class="col-lg" *ngIf="0<needToWaitTime" matTooltip="{{'theTimeWhenWillBeAbleMakePayment' | translate}}"
              matTooltipPosition="above">
              <button mat-raised-button color="primary" class="w-100" disabled>
                {{'timeLockupEnds' | translate}}: {{getTranslatedShowPeriod(needToWaitTime)}}
              </button>
            </div>
          </div>
        </div>

        <!-- If not login -->
        <div class="row gx-2" *ngIf="!kycConfirmed && !isAuthorized">
          <div class="col-lg"
            matTooltip="{{'youMustAuthorisedCompleteKycJoiningDeals' | translate}}" matTooltipPosition="above">
            <button mat-raised-button color="primary" class="w-100 btn-icon btn-icon-left" disabled>
              <i class="icon icomoon-checkmark"></i>
              {{'approve' | translate}}
            </button>
          </div>
          <div class="col-lg" matTooltip="{{'youMustAuthorisedCompleteKycJoiningDeals' | translate}}"
            matTooltipPosition="above">
            <button mat-raised-button color="primary" class="w-100" disabled>{{'pay' | translate}}</button>
          </div>
        </div>

        <div *ngIf="kycConfirmed && !canPayMore">
          <div class="col-lg" matTooltip="{{'youCantPay' | translate}}" matTooltipPosition="above">
            <button mat-raised-button color="primary" class="w-100" disabled>{{'pay' | translate}}</button>
          </div>
        </div>

        <div class="mt-4" *ngIf="needToWaitTime">
          <div class="mb-2">
            <div class="caption text-muted mb-1">{{'lastParticipation' | translate}}:</div>
            <div class="small-text">
              {{lastParticipationTime* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}
            </div>
          </div>
          <div>
            <div class="caption text-muted mb-1">{{'lockupDuration' | translate}}:</div>
            <div class="small-text">
              {{getTranslatedShowPeriod(lockupDuration)}}
            </div>
          </div>
        </div>
        </section>

      </div>
    </div>

    <div class="col-lg-4 mt-4 mt-lg-0" *ngIf="isAuthorized && kycConfirmed && deal.preview && userLockupsTierIndex >= 0">
      <div class="card p-4 min-h-100 d-flex align-items-center justify-content-center">
        <section class="w-100">

          <section *ngIf="interesWasShown && !interesAccepted && !interesDeclined">
            <div class="subtitle-1 mb-4 text-center">{{'joinInteres' | translate}}</div>
            <div class="row g-2">
              <div class="col-lg-12 mb-2">
                <button mat-raised-button color="primary" class="w-100 btn-icon btn-icon-left" (click)="setInteresting()">
                  <i class="icon icomoon-checkmark"></i>
                  {{'yes' | translate}}
                </button>
              </div>
              <div class="col-lg-12">
                <button mat-raised-button class="w-100" (click)="setNotInteresting()">
                  <i class="icon icomoon-close"></i>
                  {{'no' | translate}}</button>
              </div>
            </div>
          </section>

          <section *ngIf="interesWasSet && !interesAccepted">
            <div class="subtitle-1 mb-4 text-center">{{'setInteresTitle' | translate}}</div>
            <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field mb-4">
              <img [src]="paymentTokenSymbol | imgTokenPipe" />
              <mat-label>{{paymentTokenSymbol}}</mat-label>
              <input matInput type="number" [(ngModel)]="interesAmount" required>
            </mat-form-field>

            <div class="row g-2">
              <div class="col-lg">
                <button mat-raised-button color="primary" class="w-100 btn-icon btn-icon-left" (click)="applyInteres()" [ngClass]="{'btn-loading': waiting }" [disabled]="waiting || !kycConfirmed">
                  <i class="icon icomoon-checkmark"></i>
                  {{'set' | translate}}
                </button>
              </div>
              <div class="col-lg">
                <button mat-raised-button class="w-100" (click)="setNotInteresting()">
                  <i class="icon icomoon-close"></i>
                  {{'no' | translate}}</button>
              </div>
            </div>
          </section>

          <section *ngIf="interesDeclined">
            <div class="subtitle-1 mb-4 text-center">{{'interesDeclined' | translate}}</div>
            <div class="col-lg">
              <button mat-raised-button color="primary" class="w-100 btn-icon btn-icon-left" (click)="setInteresting()">
                <i class="icon icomoon-checkmark"></i>
                {{'yes' | translate}}
              </button>
            </div>
          </section>

          <section *ngIf="interesAccepted">
            <div class="subtitle-1 mb-4 text-center interes-amount" *ngIf="allowance">{{'interesAmount' | translate:{amount:allowance, currency:deal.paymentTokenSymbol} }}</div>
            <div class="subtitle-1 mb-4 text-center">{{'interesAccepted' | translate }}</div>
            <div class="col-lg">
              <button mat-raised-button color="primary" class="w-100 btn-icon btn-icon-left" (click)="resetInteres()" [ngClass]="{'btn-loading': waiting }" [disabled]="waiting">
                <i class="icon icomoon-checkmark"></i>
                {{'resetInteresButton' | translate}}
              </button>
            </div>
          </section>

        </section>
      </div>
    </div>
  </div>
</section>

<!-- TODO: Onchain deal - obsolete - refactor -->
<section class="dashboard-section __deal-general-info pt-40" *ngIf="vestingPercent">
  <div class="card p-4" *ngIf="vestingPercent">
    <div class="row">
      <div class="col-lg-6 mb-4">
        <div class="caption text-muted mb-1">{{'vesting' | translate}} %</div>
        <div class="small-text">
          {{vestingPercent}}%
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="caption text-muted mb-1">{{'vestingStartDate' | translate}}</div>
        <div class="small-text">
          {{vestingStart* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}} ({{'timestamp' | translate}}: {{vestingStart}})
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="caption text-muted mb-1">{{'vestingDuration' | translate}}</div>
        <div class="small-text">
          {{getTranslatedShowPeriod(vestingDuration)}}
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="caption text-muted mb-1">{{'vestingInterval' | translate}}</div>
        <div class="small-text">
          {{getTranslatedShowPeriod(vestingInterval)}}
        </div>
      </div>
    </div>
  </div>
</section>

<!-- TODO: Onchain deal - obsolete - refactor -->
<section class="dashboard-section __deal-general-info pt-40" *ngIf="isFinished && !isCollectWalletDeal">
  <div class="row">
    <div class="col-lg" *ngif="usersDebtAmount">
      <div class="card">
        <div class="card-content pb-0">
          <div class="row">
            <div class="col-lg-6 mb-4" *ngIf="usersDebtAmount">
              <div class="caption text-muted mb-1">{{'amountForClaim' | translate}}</div>
              <div class="small-text">
                {{usersDebtAmount | number: '1.0-4'}} {{rewardTokenSymbol}}
              </div>
            </div>
            <div class="col-lg-6 mb-4" *ngIf="vestingPercent">
              <div class="caption text-muted mb-1">{{'vestingAmount' | translate}}</div>
              <div class="small-text">
                {{usersDebtAmount*vestingPercent/100| number: '1.0-4' }}
              </div>
            </div>
            <div class="col-lg-6 mb-4" *ngIf="vestingPercent">
              <div class="caption text-muted mb-1">{{'receiveAmount' | translate}}</div>
              <div class="small-text">
                {{usersDebtAmount*(100-vestingPercent)/100| number: '1.0-4' }}
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer" *ngIf="usersDebtAmount && account">
          <button mat-raised-button color="primary" class="btn-icon btn-icon-left w-100" *ngIf="account"
            (click)="claimClick()" [ngClass]="{'btn-loading': waiting}" [disabled]="waiting || !allowClaim">
            <i class="icon icomoon-claim"></i>
            {{'claimYourPurchasedTokens' | translate}}
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg mt-3 mt-lg-0" *ngIf="usersClaimedTokensAmount || usersReleasableAmount || vestingPercent">
      <div class="card">
        <div class="card-content pb-0">
          <div class="row">
            <div class="col-lg-6 mb-4" *ngIf="usersClaimedTokensAmount">
              <div class="caption text-muted mb-1">{{'claimedAmount' | translate}}</div>
              <div class="small-text">
                {{usersClaimedTokensAmount | number: '1.0-4'}} {{rewardTokenSymbol}}
              </div>
            </div>
            <div class="col-lg-6 mb-4" *ngIf="vestingPercent">
              <div class="caption text-muted mb-1">{{'vestedAmount' | translate}}</div>
              <div class="small-text">
                {{usersVestedAmount | number: '1.0-4'}} {{rewardTokenSymbol}}
              </div>
            </div>
            <div class="col-lg-6 mb-4" *ngIf="vestingPercent">
              <div class="caption text-muted mb-1">{{'releasableAmount' | translate}}</div>
              <div class="small-text">
                {{usersReleasableAmount | number: '1.0-4'}} {{rewardTokenSymbol}}
              </div>
            </div>
            <div class="col-lg-6 mb-4" *ngIf="vestingPercent">
              <div class="caption text-muted mb-1">{{'vestedReleasedAmount' | translate}}</div>
              <div class="small-text">
                {{usersVestedReleasedAmount | number: '1.0-4'}} {{rewardTokenSymbol}}
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer" *ngIf="usersReleasableAmount">
          <button mat-raised-button color="primary" class="btn-icon btn-icon-left w-100" *ngIf="account"
            (click)="claimVesingClick()" [ngClass]="{'btn-loading': waiting}" [disabled]="waiting || !allowClaim">
            <i class="icon icomoon-claim"></i>
            {{'claimYourVestingTokens' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

</section>

<section class="dashboard-section __deal-tiers-info pt-40" *ngIf="!deal.preview">
  <div class="card">
    <div class="card-header">{{deal.dealType == 3 ? ('ticketSize' | translate) : ('ticketSizeAndAllocation' | translate)}}</div>
    <div class="card-content pb-4">

      <div class="deal-tiers" *ngIf="deal.dealType == 3">
        <div class="tier-item" *ngFor="let item of allTiers; index as i;">
          <img [src]="i | imgTierPipe" alt="" class="tier-item-logo">
          <p class="tier-item-type">{{getTranslatedTier(i)}}</p>
          <p class="tier-item-ticket-size">{{item.ticketSize| shiftDecimals:-rewardDecimals}} {{paymentTokenSymbol}}</p>
        </div>
      </div>

      <div class="deal-tiers" *ngIf="deal.dealType != 3">
        <div class="tier-group" *ngFor="let item of allTiers; index as i;">
          <div class="tier-group__title mb-3">
            <img [src]="i | imgTierPipe" alt="" class="tier-group-logo" />
            {{getTranslatedTier(i)}}
          </div>
          <div class="tier-group__progress d-md-flex align-items-center">
            <div class="col-auto mb-2 mb-md-0 me-md-4 align-items-center">
              <div class="tier-group__caption caption text-muted">{{'maxTicket' | translate}}</div>
              <div class="d-flex align-items-center">
                <img [src]="paymentTokenSymbol | imgTokenPipe" />
                <span class="tier-group__value">{{ (userCustomRaise > 0 && i == userTierIndex ? increaseTicketSize(item.ticketSize) : item.ticketSize) | shiftDecimals:-rewardDecimals}}</span>
                <span *ngIf="userCustomRaise > 0 && i == userTierIndex" class="ticket-increased ms-2 ticket-increased-percent" matTooltip="">
                  <img src="../../assets/images/gmpd.png" alt="">
                  +{{userCustomRaise}}%</span>
              </div>
            </div>
            <div class="col">
              <div class="tier-group__caption caption text-muted">{{'tokensSold' | translate}}</div>
              <div class="d-flex align-items-center">
                <img [src]="firstTokenIconUrl" />
                <span class="tier-group__value">{{getPurchasedFirstTokens(item.purchasedTokens)| shiftDecimals:-rewardDecimals| number: '1.0-2'}} </span>
              </div>
              <div class="d-flex align-items-center" *ngIf="deal?.secondTokenCustomPrice">
                <img [src]="secondTokenIconUrl" />
                <span class="tier-group__value">{{getPurchasedSecondTokens(item.purchasedTokens)| shiftDecimals:-rewardDecimals| number: '1.0-2'}} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="deal-progress_title mb-1 mt-4">{{'dealProgress' | translate}}</div>

      <div class="__deal-progress">
        <div *ngIf="deal.dealType != 3 && !deal?.secondTokenCustomPrice" class="caption text-right text-muted mb-1">{{getDistributedLeft | shiftDecimals:-rewardDecimals|
          number: '1.0-2' }} {{'left' | translate}}</div>
        <div class="progress progress-xs">
          <div class="progress-bar" role="progressbar" [style.width.%]="getDistributedPercent"></div>
        </div>
        <div class="progress-legend d-flex justify-content-between">
          <div class="progress-legend__percent">{{getDistributedPercent| number: '1.0-2'}}%</div>
          <div>
            <div *ngIf="deal.dealType != 3" class="progress-legend__value d-flex align-items-center">
              <span>{{firstTokenDistributionQuantity | number: '1.0-2'}}/{{firstTokenMaxDistributedTokenAmount | shiftDecimals:-rewardDecimals| number: '1.0-2'}}</span>
              <img [src]="firstTokenIconUrl" />
            </div>
            <div *ngIf="deal.dealType != 3 && deal?.secondTokenCustomPrice" class="progress-legend__value d-flex align-items-center mt-2">
              <span>{{secondTokenDistributionQuantity | number: '1.0-2'}}/{{secondTokenMaxDistributedTokenAmount | shiftDecimals:-rewardDecimals| number: '1.0-2'}}</span>
              <img [src]="secondTokenIconUrl" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<section class="dashboard-section __deal-description-info p-40" id="deal-about">
  <div class="card" *ngIf="deal.dealType != 3">
    <div class="card-header">{{'aboutTheProject' | translate}}</div>
    <div class="card-content">
      <div class="row">
        <div class="col-lg-7">
          <p>{{deal?.description}}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-7">
          <a [href]="deal?.url" *ngIf="deal?.url" mat-button color="primary" class="icon-link short-btn"
            target="_blank">
            <i class="icon icomoon-list"></i>
            {{'learnMore' | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
</ng-container>
